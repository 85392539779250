import { mapGetters } from 'vuex'

export default {
  name: 'CountryContainer',
  props: {
    content: {
      type: Array,
      required: true
    },
    countries: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      currentCountryCode: 'frontend/currentCountryCode'
    }),
    blocks () {
      // Use this method to filter out if the block should display
      // depending on country, if it should use a default one or not etc
      return this.content.filter(() => {
        return !this.countries.length || this.countries.includes(this.currentCountryCode.toString())
      })
    }
  },
  render (createElement) {
    // There is no reason to render anything if we do not have any blocks
    if (this.blocks.length === 0) {
      return null
    }
    const children = this.blocks.map(block =>
      createElement(
        block.component,
        {
          props: {
            ...block
          }
        }
      )
    )
    return createElement('div', {
      style: { display: 'contents' }
    }, children)
  }
}
